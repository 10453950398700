import React, { useState } from 'react'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useGetPageTypeItems } from 'Components/result/resultHooks'
import classNames from 'classnames'
import { toCurrency } from '@/utils/format'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const Result = (props) => {
  const [stylesMain] = useState('Result')
  // const { state, partnerHash} = useState()
  const {
    amount,
    asset: { tokenName },
    from,
    to,
    memo,
    txHashes,
    removeResult,
    transferInfo,
    transfermap,
    isToBSC,
  } = props

  const { items } = useGetPageTypeItems(props)
  const SrcIcon = items.chain.src
  const DestIcon = items.chain.dest

  return (
    <Container className={classNames(stylesMain, 'p-0')}>
      <Row>
        <Col className={`${stylesMain}__content`}>
          <Card className={`${stylesMain}__content__card`}>
            <Card.Body className="p-0">
              <div
                className={classNames({
                  [`${stylesMain}__content__body`]: true,
                })}
              >
                <Container className="p-0">
                  <Row
                    className={classNames(
                      'm-0',
                      `${stylesMain}__content__body__row`,
                    )}
                  >
                    <Col
                      className={classNames(
                        `${stylesMain}__content__body__status`,
                      )}
                    >
                      <span>{items.title}</span>
                    </Col>
                  </Row>
                  <Row
                    className={classNames(
                      'm-0',
                      `${stylesMain}__content__body__row`,
                      'justify-content-start',
                    )}
                  >
                    <Col
                      sm={3}
                      className={classNames({
                        [`${stylesMain}__content__body__title`]: true,
                      })}
                    >
                      <span
                        className={classNames(
                          'label--dark',
                          'text-format-title',
                          `${stylesMain}__content__body__title__text`,
                        )}
                      >
                        From
                      </span>
                      <SrcIcon style={{ width: '8rem' }}></SrcIcon>
                    </Col>
                    <Col
                      sm={8}
                      className={classNames({
                        [`${stylesMain}__content__body__title`]: true,
                      })}
                    >
                      <span
                        className={classNames(
                          'label--dark',
                          'text-format-title',
                        )}
                      >
                        {from}
                      </span>
                    </Col>
                    <Col sm={1} className="text-right">
                      <CopyToClipboard text={from}>
                        <Button
                          style={{ backgroundColor: '#da1a5e', border: 'none' }}
                        >
                          <img
                            src={require('@/assets/icons/copy.png')}
                            style={{ width: '2rem' }}
                            alt=""
                          />
                        </Button>
                      </CopyToClipboard>
                    </Col>
                  </Row>
                  <Row
                    className={classNames(
                      'm-0',
                      `${stylesMain}__content__body__row`,
                      'justify-content-start',
                    )}
                  >
                    <Col
                      sm={3}
                      className={classNames(
                        `${stylesMain}__content__body__title`,
                      )}
                    >
                      <span
                        className={classNames(
                          'label--dark',
                          'text-format-title',
                          `${stylesMain}__content__body__title__text`,
                        )}
                        style={{ paddingRight: '3.8rem' }}
                      >
                        To
                      </span>
                      <DestIcon style={{ width: '8rem' }}></DestIcon>
                    </Col>
                    <Col
                      sm={8}
                      className={classNames({
                        [`${stylesMain}__content__body__title`]: true,
                      })}
                    >
                      <span
                        className={classNames(
                          'label--dark',
                          'text-format-title',
                        )}
                      >
                        {to}
                      </span>
                    </Col>
                    <Col sm={1} className="text-right">
                      <CopyToClipboard text={to}>
                        <Button
                          style={{ backgroundColor: '#da1a5e', border: 'none' }}
                        >
                          <img
                            src={require('@/assets/icons/copy.png')}
                            style={{ width: '2rem' }}
                            alt=""
                          />
                        </Button>
                      </CopyToClipboard>
                    </Col>
                  </Row>
                  <Row
                    className={classNames(
                      'm-0',
                      `${stylesMain}__content__body__row`,
                      'justify-content-start',
                    )}
                  >
                    <Col
                      sm={3}
                      className={classNames({
                        [`${stylesMain}__content__body__title`]: true,
                      })}
                    >
                      <span
                        className={classNames(
                          'label--dark',
                          'text-format-title',
                          `${stylesMain}__content__body__title__text`,
                        )}
                      >
                        Amount
                      </span>
                    </Col>
                    <Col
                      sm={8}
                      className={classNames(
                        `${stylesMain}__content__body__title`,
                        'text-format-title-bold',
                      )}
                    >
                      {/* <span>{`${toCurrency(amount)} ${code}`}</span> */}
                      <span
                        className={classNames(
                          'label--dark',
                          'text-format-title',
                        )}
                      >
                        {`${toCurrency(amount)} ${tokenName}`}
                      </span>
                    </Col>
                    <Col sm={1} className="text-right">
                      <CopyToClipboard text={amount}>
                        <Button
                          style={{ backgroundColor: '#da1a5e', border: 'none' }}
                        >
                          <img
                            src={require('@/assets/icons/copy.png')}
                            style={{ width: '2rem' }}
                            alt=""
                          />
                        </Button>
                      </CopyToClipboard>
                    </Col>
                  </Row>
                  {isToBSC === false ? (
                    <div>
                      <Row
                        className={classNames(
                          'm-0',
                          `${stylesMain}__content__body__row`,
                          'justify-content-start',
                        )}
                      >
                        <Col
                          sm={3}
                          className={classNames({
                            [`${stylesMain}__content__body__title`]: true,
                          })}
                        >
                          <span
                            className={classNames(
                              'label--dark',
                              'text-format-title',
                              `${stylesMain}__content__body__title__text`,
                            )}
                          >
                            Memo
                          </span>
                        </Col>
                        <Col
                          sm={8}
                          className={classNames(
                            `${stylesMain}__content__body__title`,
                            'text-format-title-bold',
                          )}
                        >
                          {/* <span>{`${toCurrency(amount)} ${code}`}</span> */}
                          <span
                            className={classNames(
                              'label--dark',
                              'text-format-title',
                            )}
                          >
                            {memo}
                          </span>
                        </Col>
                        <Col sm={1} className="text-right">
                          <CopyToClipboard text={memo}>
                            <Button
                              style={{
                                backgroundColor: '#da1a5e',
                                border: 'none',
                              }}
                            >
                              <img
                                src={require('@/assets/icons/copy.png')}
                                style={{ width: '2rem' }}
                                alt=""
                              />
                            </Button>
                          </CopyToClipboard>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <hr />

                  <Row
                    className={classNames(
                      'm-0',
                      `${stylesMain}__content__body__row`,
                    )}
                  >
                    <Col
                      className={classNames({
                        [`${stylesMain}__content__hash`]: true,
                      })}
                    >
                      <Card>
                        <Card.Body
                          className={classNames({
                            [`${stylesMain}__content__hash__content`]: true,
                          })}
                        >
                          <span
                            className={classNames(
                              'text-format-label',
                              'd-block',
                            )}
                          >
                            For BSC
                          </span>
                          <Container className="m-0">
                            {txHashes.state.bsc ? (
                              <Row className="p-0 justify-content-between">
                                <Col>
                                  <span
                                    className={classNames('text-format-input')}
                                  >
                                    {txHashes.state.bsc}
                                  </span>
                                </Col>
                                <Col className="text-right">
                                  <CopyToClipboard text={txHashes.state.bsc}>
                                    <Button
                                      style={{
                                        backgroundColor: '#da1a5e',
                                        border: 'none',
                                      }}
                                    >
                                      <img
                                        src={require('@/assets/icons/copy.png')}
                                        style={{ width: '2rem' }}
                                        alt=""
                                      />
                                    </Button>
                                  </CopyToClipboard>
                                </Col>
                              </Row>
                            ) : transfermap.state &&
                              transfermap.state.state == 2 ? (
                              <Row className="p-0 justify-content-between">
                                <Col>
                                  <span
                                    className={classNames('text-format-input')}
                                  >
                                    {transfermap.state.bscHash}
                                  </span>
                                </Col>
                                <Col className="text-right">
                                  <CopyToClipboard
                                    text={transfermap.state.bscHash}
                                  >
                                    <Button
                                      style={{
                                        backgroundColor: '#da1a5e',
                                        border: 'none',
                                      }}
                                    >
                                      <img
                                        src={require('@/assets/icons/copy.png')}
                                        style={{ width: '2rem' }}
                                        alt=""
                                      />
                                    </Button>
                                  </CopyToClipboard>
                                </Col>
                              </Row>
                            ) : (
                              <Row>
                                <Col
                                  className={classNames('text-format-input')}
                                >
                                  <span> Please wait a moment.</span>
                                </Col>
                                <Col className={'text-right'}>
                                  <Button
                                    onClick={() => {
                                      transferInfo(txHashes.state.stellar)
                                    }}
                                    variant="secondary"
                                    className={classNames(
                                      `${stylesMain}__close__btn`,
                                    )}
                                    style={{
                                      width: '8rem',
                                      height: '3rem',
                                    }}
                                  >
                                    Refresh
                                  </Button>
                                </Col>
                              </Row>
                            )}
                          </Container>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  {/* TODO: */}

                  <Row
                    className={classNames(
                      'm-0',
                      `${stylesMain}__content__body__row`,
                    )}
                  >
                    <Col
                      className={classNames({
                        [`${stylesMain}__content__hash`]: true,
                      })}
                    >
                      <Card>
                        <Card.Body
                          className={classNames({
                            [`${stylesMain}__content__hash__content`]: true,
                          })}
                        >
                          <span
                            className={classNames(
                              'text-format-label',
                              'd-block',
                            )}
                          >
                            For Stellar
                          </span>
                          <Container className="m-0">
                            {txHashes.state.stellar ? (
                              <Row className="p-0 justify-content-between">
                                <Col>
                                  <span
                                    className={classNames('text-format-input')}
                                  >
                                    {txHashes.state.stellar}
                                  </span>
                                </Col>
                                <Col className="text-right">
                                  <CopyToClipboard
                                    text={txHashes.state.stellar}
                                  >
                                    <Button
                                      style={{
                                        backgroundColor: '#da1a5e',
                                        border: 'none',
                                      }}
                                    >
                                      <img
                                        src={require('@/assets/icons/copy.png')}
                                        style={{ width: '2rem' }}
                                        alt=""
                                      />
                                    </Button>
                                  </CopyToClipboard>
                                </Col>
                              </Row>
                            ) : transfermap.state &&
                              transfermap.state.state == 2 ? (
                              <Row className="p-0 justify-content-between">
                                <Col>
                                  <span
                                    className={classNames('text-format-input')}
                                  >
                                    {transfermap.state.partnerHash}
                                  </span>
                                </Col>
                                <Col className="text-right">
                                  <CopyToClipboard
                                    text={transfermap.state.partnerHash}
                                  >
                                    <Button
                                      style={{
                                        backgroundColor: '#da1a5e',
                                        border: 'none',
                                      }}
                                    >
                                      <img
                                        src={require('@/assets/icons/copy.png')}
                                        style={{ width: '2rem' }}
                                        alt=""
                                      />
                                    </Button>
                                  </CopyToClipboard>
                                </Col>
                              </Row>
                            ) : (
                              <Row>
                                <Col
                                  className={classNames('text-format-input')}
                                >
                                  <span> Please wait a moment.</span>
                                </Col>

                                <Col className={'text-right'}>
                                  <Button
                                    onClick={() => {
                                      transferInfo(txHashes.state.bsc)
                                    }}
                                    variant="secondary"
                                    className={classNames(
                                      `${stylesMain}__close__btn`,
                                    )}
                                    style={{
                                      width: '8rem',
                                      height: '3rem',
                                    }}
                                  >
                                    Refresh
                                  </Button>
                                </Col>
                              </Row>
                            )}
                          </Container>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row
                    className={classNames(
                      'm-0',
                      `${stylesMain}__content__body__row`,
                    )}
                  >
                    <Col className={`${stylesMain}__close`}>
                      <Button
                        onClick={() => {
                          removeResult(), window.location.reload()
                        }}
                        variant="secondary"
                        className={classNames(`${stylesMain}__close__btn`)}
                      >
                        Done
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

Result.propTypes = {
  amount: PropTypes.string.isRequired,
  asset: PropTypes.shape({
    tokenName: PropTypes.string,
  }).isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  memo: PropTypes.string.isRequired,
  txHashes: PropTypes.shape({
    state: PropTypes.shape({
      stellar: PropTypes.string,
      bsc: PropTypes.string,
    }),
    error: PropTypes.object,
  }).isRequired,
  transfermap: PropTypes.shape({
    state: PropTypes.shape({
      amount: PropTypes.string,
      bscHash: PropTypes.string,
      partnerHash: PropTypes.string,
      bscAddr: PropTypes.string,
      partnerAddr: PropTypes.string,
      direction: PropTypes.string,
      finishedTime: PropTypes.string,
      state: PropTypes.string,
    }),
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
  removeResult: PropTypes.func.isRequired,
  transferInfo: PropTypes.func.isRequired,
  isToBSC: PropTypes.bool.isRequired,
}

export default Result
