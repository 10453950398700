import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import classNames from 'classnames'
import { ReactComponent as Copyright } from '@/assets/icons/copyright.svg'

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      styles: {
        main: classNames({
          [this.constructor.name]: true,
        }),
      },
    }
  }

  render() {
    return (
      <Navbar
        expand="lg"
        sticky="bottom"
        bg="light"
        className={this.state.styles.main}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 2rem',
          position: 'fixed',
          width: '100%',
        }}
      >
        <Navbar.Brand>
          <Copyright></Copyright>
        </Navbar.Brand>
        <span style={{ fontSize: '17.4px', color: '#86909c' }}>
          Contact us:
          <b>
            <a href="mailto:support@velo.org" style={{ color: '#333' }}>
              &nbsp;support@velo.org
            </a>
          </b>{' '}
        </span>
      </Navbar>
    )
  }
}
