import { createStructuredSelector } from 'reselect'
import actionTypes from 'Components/hermes/hermesActionTypes'

export const isToBscSelector = (state) =>
  state.hermes[actionTypes.ASYNC_TOGGLE_WARP_SWITCH.stateKey]

export const userAccount = (state) =>
  state.hermes[actionTypes.USER_ACCOUNT.stateKey]

export const selectCollectedTxHashes = createStructuredSelector({
  state: (state) => {
    return state.hermes[actionTypes.ASYNC_COLLECT_HASHES.stateKey]
  },
  loading: (state) => {
    return state.hermes[actionTypes.ASYNC_COLLECT_HASHES.loadingKey]
  },
  error: (state) => {
    return state.hermes[actionTypes.ASYNC_COLLECT_HASHES.errorKey]
  },
})

export const selectTransferInfo = createStructuredSelector({
  state: (state) => {
    return state.hermes[actionTypes.ASYNC_GET_TRANSFERINFO.stateKey]
  },
  loading: (state) => {
    return state.hermes[actionTypes.ASYNC_GET_TRANSFERINFO.loadingKey]
  },
  error: (state) => {
    return state.hermes[actionTypes.ASYNC_GET_TRANSFERINFO.errorKey]
  },
})

export const selectWhitelistedAssets = createStructuredSelector({
  state: (state) => {
    return state.hermes[actionTypes.ASYNC_GET_WHITELISTED_ASSETS.stateKey]
  },
  loading: (state) => {
    return state.hermes[actionTypes.ASYNC_GET_WHITELISTED_ASSETS.loadingKey]
  },
  error: (state) => {
    return state.hermes[actionTypes.ASYNC_GET_WHITELISTED_ASSETS.errorKey]
  },
})

export const selectAccountBalance = createStructuredSelector({
  state: (state) => {
    return state.hermes[actionTypes.ASYNC_GET_ACCOUNT_BALANCE.stateKey]
  },
  loading: (state) => {
    return state.hermes[actionTypes.ASYNC_GET_ACCOUNT_BALANCE.loadingKey]
  },
  error: (state) => {
    return state.hermes[actionTypes.ASYNC_GET_ACCOUNT_BALANCE.errorKey]
  },
})

export const selectTrustlines = createStructuredSelector({
  state: (state) => {
    return state.hermes[actionTypes.ASYNC_GET_TRUSTLINES.stateKey]
  },
  loading: (state) => {
    return state.hermes[actionTypes.ASYNC_GET_TRUSTLINES.loadingKey]
  },
  error: (state) => {
    return state.hermes[actionTypes.ASYNC_GET_TRUSTLINES.errorKey]
  },
})
