import React, { Component } from 'react'
import classNames from 'classnames'
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap'
import { MaskPasswordInput } from 'Components/shared'
import { removeTrailingDot, removeLeadingZero } from '@/utils/format'
import PropTypes from 'prop-types'
import { getHermesInstance } from '@/utils/singleton'
import { map, isEmpty, find, split, reduce, has, min, isNaN } from 'lodash'
import BigNumber from 'bignumber.js'
import StellarBase from 'stellar-base'
import config from '@/config'
import { Select } from 'Components/shared'
import { ResultComponent } from 'Components/result'
import { CrossModalComponent } from 'Components/crossModal'
import produce from 'immer'
import Modal from 'react-modal'
import Web3 from 'web3'

// import store from '../../../store'
const {
  bsc: { ATOMIC_STELLAR_DECIMAL_UNIT },
} = config

export default class HermesContent extends Component {
  constructor(props) {
    super(props)
    this.hermes = getHermesInstance()
    this._validateAvailableAmounts = this._validateAvailableAmounts.bind(this)
    this._validateTrustlines = this._validateTrustlines.bind(this)
    this._crossSubmit = this._crossSubmit.bind(this)
    this._crossBscSubmit = this._crossBscSubmit.bind(this)
    // const selectedAddress =
    //   window['ethereum'].selectedAddress == null
    //     ? ''
    //     : window['ethereum'].selectedAddress

    this.initialState = {
      stylesMain: this.constructor.name,
      error: null,
      formControls: {
        asset: {
          value: 'VELO', // this.hermes.utils.getEvryAsset().getTokenname(),
          effects: [{ name: 'amount', funcs: [this._validate] }],
        },
        amount: {
          name: 'Amount',
          value: '',
          placeholder: 'Enter an amount',
          touched: false,
          valid: false,
          validations: [
            this._validateNotEmpty,
            this._validateIsNumber,
            this._validateNumberDecimal,
            this._validateMoreThanZero,
          ],
          formats: [removeLeadingZero, removeTrailingDot],
        },
        memo: {
          name: 'Memo',
          value: '',
          placeholder: 'Optional (Enter a maximum of 28 characters)',
          //   touched: false,
          //   valid: false,
          validations: [
            this._validateMemoMaximum,
            // this._validateStellarAddressFormat,
          ],
        },
        sourceAccount: {
          name: 'Stellar Address',
          value: '',
          //   value: props.userAccount,
          placeholder: 'Stellar Address',
          touched: false,
          valid: false,
          validations: [
            this._validateNotEmpty,
            this._validateStellarAddressFormat,
          ],
        },
        destinationAccount: {
          name: 'BSC Address',
          value: '',
          //   value: selectedAddress,
          placeholder: 'BSC Address',
          touched: false,
          valid: false,
          validations: [this._validateNotEmpty, this._validateBscAddressFormat],
        },
        form: {
          disabled: false,
          effects: [
            {
              name: 'amount',
              funcs: [this._validateAvailableAmounts],
            },
            {
              name: 'destinationAccount',
              funcs: [this._validateTrustlines],
            },
          ],
        },
      },
      transferFunc: props.stellarToBsc,
      result: null,
      crossResult: null,
      showResult: false,
      isTransactionModal: false,
      toAddr: '',
    }

    this.state = {
      ...this.initialState,
    }
  }

  /*
    core state functions
   */
  _disableButton(isDisable) {
    this.setState(
      produce((draft) => {
        draft.formControls.form.disabled = isDisable
      }),
    )
  }

  async _validate(e, formControls) {
    if (has(e, 'validations')) {
      e.valid = true
      e.errorMessage = null
      e.validations.forEach((validate) => {
        if (e.valid) {
          validate(e, formControls)
        }
      })
    }
  }

  async _effect(sourceElement, formControls) {
    const promises = []
    if (has(sourceElement, 'effects')) {
      for (const effected of sourceElement.effects) {
        formControls[effected.name].touched = true
        for (const effect of effected.funcs) {
          promises.push(effect(formControls[effected.name], formControls))
        }
      }
    }
    await Promise.all(promises)
  }

  async _bscTransfer() {
    if (this._isTransferDisabled()) return

    const asset = this._getWhitelistedAssetByCode(
      this.state.formControls.asset.value,
    )
    // console.log(asset)

    this.hermes.client.ether.config.contract.etherCustodian.address = asset.evrynetContractAddr
    this.hermes.client.ether.config.contract.etherErc20ContractAddr.address = asset.tokenEvrynetAddr

    const payload = {
      asset,
      amount: this.state.formControls.amount.value,
      from: this.state.formControls.sourceAccount.value,
      to: this.state.formControls.destinationAccount.value,
      memo: this.state.formControls.memo.value,
    }
    // console.log(payload)
    await this.state.transferFunc(payload)
    if (this.props.txHashes.state) {
      const result = {
        ...payload,
        ...asset,
        isToBSC: this.props.isToBSC,
        txHashes: {
          state: this.props.txHashes.state,
        },
      }
      this.setState({
        result,
        error: null,
      })

      return
    }
    if (this.props.txHashes.error) {
      // console.log(this.props.txHashes.error.message.code)
      // TODO:
      if (this.props.txHashes.error.message.code == 4001) {
        window.location.reload()
      } else {
        // TODO:
        // window.location.reload()
        this.setState((_, props) => ({
          error: props.txHashes.error.toString(),
        }))
      }
    }
  }

  async _transfer(value) {
    // console.log(value)
    if (this._isTransferDisabled()) return

    const asset = this._getWhitelistedAssetByCode(
      this.state.formControls.asset.value,
    )

    this.hermes.client.stellar.config.custodianAccount = asset.partnerCustodianAddr
    this.hermes.client.stellar.config.issuer = asset.tokenPartnerInfo

    const payload = {
      asset,
      amount: this.state.formControls.amount.value,
      fromPriv: value,
      from: this.state.formControls.sourceAccount.value,
      to: this.state.formControls.destinationAccount.value,
    }
    // console.log(payload)
    await this.state.transferFunc(payload)
    // console.log(this.props.txHashes)
    if (this.props.txHashes.state) {
      const result = {
        ...payload,
        ...asset,
        isToBSC: this.props.isToBSC,
        txHashes: {
          state: this.props.txHashes.state,
        },
      }
      this.setState({
        result,
        error: null,
      })
      return
    }
    if (this.props.txHashes.error) {
      this.setState((_, props) => ({
        error: props.txHashes.error.toString(),
      }))
    }
  }

  async _setStateResult() {
    if (this._isTransferDisabled()) return
    const asset = this._getWhitelistedAssetByCode(
      this.state.formControls.asset.value,
    )
    //   TODO:
    const crossResult = {
      asset,
      amount: this.state.formControls.amount.value,
      memo: this.state.formControls.memo.value,
      src: this.state.formControls.sourceAccount.value,
      dest: this.state.formControls.destinationAccount.value,
      isToBSC: this.props.isToBSC,
    }

    this.setState({
      crossResult,
    })
    return
  }

  _format(e) {
    if (e.valid && has(e, 'formats')) {
      e.formats.forEach((format) => {
        e.value = format(e.value)
      })
    }
    return e
  }

  /*
    validation functions
   */

  _validateNotEmpty(e) {
    if (!e.value) {
      e.valid = false
      e.errorMessage = `${e.name} is required.`
    }
    return e
  }

  _validateStellarSecretFormat(e) {
    if (!StellarBase.StrKey.isValidEd25519SecretSeed(e.value)) {
      e.valid = false
      e.errorMessage = 'Invalid Stellar secret key format.'
    }
    return e
  }

  _validateBscSecretFormat(e) {
    if (!/^[a-f0-9]{64}$/i.test(e.value)) {
      e.valid = false
      e.errorMessage = 'Invalid BSC secret key format.'
    }
    return e
  }

  _validateBscAddressFormat(e) {
    let address = Web3.utils.isAddress(e.value)
    if (!address) {
      e.valid = false
      e.errorMessage = 'Invalid BSC address format.'
    }
    return e
  }

  _validateStellarAddressFormat(e) {
    if (!StellarBase.StrKey.isValidEd25519PublicKey(e.value)) {
      e.valid = false
      e.errorMessage = 'Invalid Stellar address format.'
    }
    return e
  }

  _validateIsNumber(e) {
    if (isNaN(Number(e.value))) {
      e.valid = false
      e.errorMessage = 'Amount must be a number.'
    }
    return e
  }

  _validateNumberDecimal(e) {
    if (!/^\d+(.\d{0,7})?$/.test(e.value)) {
      e.valid = false
      e.errorMessage = 'Up to 7 decimal places.'
    }
    return e
  }

  _validateMoreThanZero(e) {
    if (Number(e.value) <= 0) {
      e.valid = false
      e.errorMessage = 'Amount must be greater than zero.'
    }
    return e
  }

  _validateMemoMaximum(e) {
    // console.log(e.value.length)
    if ((!/^(.|\n){0,20}$/ && e.value !== '') || e.value.length > 20) {
      e.valid = false
      e.errorMessage = 'Enter a maximum of 28 characters.'
    }
    return e
  }

  _validateDecimal(e, formControls) {
    const parts = split(e.value, '.')
    const hasDecimals = parts.length >= 2
    const whitelistedAsset = this._getWhitelistedAssetByCode(
      formControls.asset.value,
    )
    const decimal = min([
      ATOMIC_STELLAR_DECIMAL_UNIT,
      whitelistedAsset.getDecimal(),
    ])
    if (hasDecimals && parts[1].length > decimal) {
      e.valid = false
      e.errorMessage = `Amount can only support a precision of ${decimal} decimals.`
    }
    return e
  }

  async _validateAvailableAmounts(e, formControls) {
    const whitelistedAsset = this._getWhitelistedAssetByCode(
      formControls.asset.value,
    )
    await this.props.getTrustlines({
      privateKey: formControls.sourceAccount.value,
    })
    const trustlinesCount = this.props.trustlines.state.length
    await this.props.getAccountBalance({
      asset: whitelistedAsset,
      privateKey: formControls.sourceAccount.value,
    })
    const decimal = this.props.isToBSC
      ? ATOMIC_STELLAR_DECIMAL_UNIT
      : whitelistedAsset.getDecimal()
    const stellarMinBalance = new BigNumber(
      (2 + trustlinesCount) * 0.5,
    ).shiftedBy(decimal)
    const isGreaterThanOrEqual = new BigNumber(
      this.props.accountBalance.state,
    ).isGreaterThanOrEqualTo(
      new BigNumber(formControls.amount.value).shiftedBy(decimal),
    )
    const isLumens = whitelistedAsset.toStellarFormat().isNative()
    const hasMinimumBalance =
      this.props.isToBSC && isLumens
        ? new BigNumber(this.props.accountBalance.state)
            .minus(StellarBase.BASE_FEE)
            .minus(new BigNumber(formControls.amount.value).shiftedBy(decimal))
            .isGreaterThanOrEqualTo(stellarMinBalance)
        : true
    e.valid = isGreaterThanOrEqual && hasMinimumBalance
    e.errorMessage = e.valid ? null : 'Insufficient Amount'
    return e
  }

  async _validateTrustlines(e, formControls) {
    // validate only on moving asset to Stellar
    if (this.props.isToBSC) return e
    const selectedAsset = this._getWhitelistedAssetByCode(
      formControls.asset.value,
    )
    // skip validation when asset type is Stellar's native
    if (selectedAsset.code === 'XLM' && !selectedAsset.issuer) return e
    await this.props.getTrustlines({
      privateKey: formControls.destinationAccount.value,
    })
    const trustlines = this.props.trustlines.state
    e.valid = trustlines.some((trustline) => {
      return (
        trustline.code === selectedAsset.code &&
        trustline.issuer === selectedAsset.issuer
      )
    })
    e.errorMessage = e.valid
      ? null
      : `The recipient Stellar account has no ${selectedAsset.code} trustline.`
    return e
  }

  /*
    handler functions
   */

  async _changeHandler({ name, value }) {
    // console.log(name)
    // console.log(value)
    const updater = async (state) => {
      return await produce(state, async (draft) => {
        draft.formControls[name].touched = true
        draft.formControls[name].value = value
        await this._validate(draft.formControls[name], draft.formControls)
        await this._effect(draft.formControls[name], draft.formControls)
      })
    }
    const nextState = await updater(this.state)
    this.setState(nextState)
  }

  async _blurHandler({ name }) {
    // console.log(name)
    const updater = async (state) => {
      return await produce(state, async (draft) => {
        draft.formControls[name].touched = true
        await this._validate(draft.formControls[name], draft.formControls)
        this._format(draft.formControls[name])
      })
    }
    const nextState = await updater(this.state)
    this.setState(nextState)
  }

  async _submitHandler() {
    try {
      this._disableButton(true)
      this._handleCrossesult()
      await this._setStateResult()
      this._disableButton(false)
    } catch (err) {
      this.setState({
        error: err.toString(),
      })
    }
  }
  async _crossBscSubmit() {
    try {
      this._removeCrossesult()
      this.props.startLoading()
      await this._bscTransfer()
      this.props.stopLoading()
    } catch (err) {
      this.setState({
        error: err.toString(),
      })
    }
  }
  async _crossSubmit(value) {
    // console.log(value)
    try {
      this._removeCrossesult()
      this.props.startLoading()
      await this._transfer(value)
      this.props.stopLoading()
    } catch (err) {
      this.setState({
        error: err.toString(),
      })
    }
  }

  /*
    others functions
   */

  _listWhitelistedAssetsOptions() {
    if (
      this.props.whitelistedAssets.loading ||
      isEmpty(this.props.whitelistedAssets.state)
    )
      return
    return map(this.props.whitelistedAssets.state, (ech) => {
      return {
        value: ech.getTokenname(),
        label: ech.getTokenname(),
        name: 'asset',
      }
    })
  }

  _getWhitelistedAssetByCode(code) {
    return find(this.props.whitelistedAssets.state, (ech) => {
      return ech.getTokenname() === code
    })
  }

  _isTransferDisabled() {
    let result =
      reduce(
        this.state.formControls,
        (res, ech) => {
          if (has(ech, 'valid')) return res || !ech.valid
          //   console.log(res)
          return res
        },
        false,
      ) || this.state.formControls.form.disabled
    return result
  }

  _isLogin() {
    let result = false
    if (this.props.isLogin === true) {
      result = true
    } else {
      result = false
    }
    return result
  }

  async _onSubmit({ name }) {
    const updater = async (state) => {
      return await produce(state, async (draft) => {
        draft.formControls[name].touched = true
        await this._effect(draft.formControls[name], draft.formControls)
      })
    }
    const nextState = await updater(this.state)
    this.setState(nextState)
  }

  _removeResult() {
    this.setState({
      result: null,
      showResult: false,
    })
  }

  _removeCrossesult() {
    this.setState({
      result: null,
      isTransactionModal: false,
    })
  }
  // 	connectMetaMask(e) {

  //   }

  async _handleIsToBsc(prevProps) {
    if (prevProps.isToBSC === this.props.isToBSC) return
    const elements = ['sourceAccount', 'destinationAccount', 'amount']
    const validationPromises = []
    const switchAccount = produce((draft, props) => {
      draft.transferFunc = props.isToBSC
        ? props.stellarToBsc
        : props.bscToStellar
      const temp = draft.formControls.sourceAccount
      draft.formControls.sourceAccount = draft.formControls.destinationAccount
      draft.formControls.destinationAccount = temp
      for (const e of elements) {
        validationPromises.push(this._validate(draft[e], draft))
      }
      Promise.all(validationPromises)
    })
    this.setState(switchAccount)
  }

  _handleResult(prevState) {
    if (prevState.result === this.state.result) return
    this.setState((state) => ({
      showResult: !!state.result,
    }))
  }

  _handleCrossesult() {
    this.setState({
      isTransactionModal: true,
    })
  }

  getUserAccount(prevProps) {
    if (this.props.isLogin === prevProps.isLogin) return
    // console.log(this.props.userAccount)
    let sourceAccountData = Object.assign(
      {},
      this.state.formControls.sourceAccount,
      {
        value: this.props.userAccount,
      },
    )
    // console.log(data)

    const formControlsData = Object.assign({}, this.state.formControls, {
      sourceAccount: sourceAccountData,
    })
    this.setState({
      formControls: formControlsData,
    })
    this._blurHandler({ name: 'sourceAccount' })

    // console.log(this.state.formControls)
  }

  async componentDidUpdate(prevProps, prevState) {
    await this._handleIsToBsc(prevProps)
    await this.getUserAccount(prevProps)
    this._handleResult(prevState)
    // console.log(606, this.props)
  }

  async componentDidMount() {
    this.props.startLoading()
    await this.props.getWhitelistAssets()
    this.props.stopLoading()
  }

  render() {
    const resultProps = {
      removeResult: this._removeResult.bind(this),
      transferInfo: this.props.getTransferInfo,
      transfermap: this.props.transferMap,
      ...this.state.result,
    }
    const resultCrossProps = {
      removeCrossResult: this._removeCrossesult.bind(this),
      crossSubmit: this._crossSubmit,
      crossBscSubmit: this._crossBscSubmit,
      ...this.state.crossResult,
    }

    return (
      <React.Fragment>
        <Form
          name="form"
          className={`${this.state.stylesMain}__form`}
          onSubmit={async (e) => {
            event.preventDefault()
            await this._submitHandler(e.target)
          }}
        >
          <Card.Body className={`${this.state.stylesMain}__form__content`}>
            <Container fluid className="px-0">
              <Row className="mx-auto my-0 pb-5">
                <Col
                  className={classNames('flex-grow-1', 'px-0', [
                    `${this.state.stylesMain}__form__content__col`,
                  ])}
                >
                  <Form.Group controlId="sourceAccountNumber">
                    <Form.Label
                      className={classNames(
                        // 'text-format-label-light',
                        `${this.state.stylesMain}__form__content__label`,
                        'text-format-title-light',
                        'font-weight-bold',
                      )}
                    >
                      <span style={{ color: '#da1a5e' }}>From</span>
                    </Form.Label>
                    <MaskPasswordInput
                      className={classNames(
                        `${this.state.stylesMain}__form__content__input__src`,
                        `${this.state.stylesMain}__form__content__input`,
                        'input-form',
                      )}
                      ismasked="text"
                      name="sourceAccount"
                      type="text"
                      placeholder={
                        this.state.formControls.sourceAccount.placeholder
                      }
                      value={this.state.formControls.sourceAccount.value}
                      onChange={(e) => {
                        this._changeHandler(e.target)
                      }}
                      onBlur={(e) => {
                        this._blurHandler(e.target)
                      }}
                      isInvalid={
                        this.state.formControls.sourceAccount.touched &&
                        !this.state.formControls.sourceAccount.valid
                      }
                      // TODO:readOnly
                      readOnly
                    />
                    <Form.Control.Feedback
                      className={classNames(
                        'position-absolute',
                        this.state.formControls.sourceAccount.touched &&
                          !this.state.formControls.sourceAccount.valid
                          ? 'd-block'
                          : '',
                      )}
                      type="invalid"
                    >
                      <div
                        className={`${this.state.stylesMain}__form__content__errorFeedback__form`}
                      >
                        {this.state.formControls.sourceAccount.errorMessage}
                      </div>
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                {/* <Col
                  className={classNames(
                    `${this.state.stylesMain}__form__content__col`,
                    'px-0',
                  )}
                >
                  <span>
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </Col> */}
              </Row>
              <Row className="mx-auto my-0" style={{ paddingTop: '4rem' }}>
                <Col
                  className={classNames(
                    `${this.state.stylesMain}__form__content__col`,
                    'flex-grow-1',
                    'px-0',
                  )}
                >
                  <Form.Group controlId="destinationAccountNumber">
                    <Form.Label
                      className={classNames(
                        // 'text-format-label-light',
                        `${this.state.stylesMain}__form__content__label`,
                        'text-format-title-light',
                        'font-weight-bold',
                      )}
                    >
                      <span style={{ color: '#da1a5e' }}>To</span>
                    </Form.Label>
                    <MaskPasswordInput
                      ismasked="text"
                      className={classNames(
                        `${this.state.stylesMain}__form__content__input__dest`,
                        `${this.state.stylesMain}__form__content__input`,
                        'input-form',
                      )}
                      name="destinationAccount"
                      type="text"
                      placeholder={
                        this.state.formControls.destinationAccount.placeholder
                      }
                      value={this.state.formControls.destinationAccount.value}
                      onChange={(e) => {
                        this._changeHandler(e.target)
                      }}
                      onBlur={(e) => {
                        this._blurHandler(e.target)
                      }}
                      isInvalid={
                        this.state.formControls.destinationAccount.touched &&
                        !this.state.formControls.destinationAccount.valid
                      }
                    />
                    <Form.Control.Feedback
                      className={classNames(
                        'position-absolute',
                        this.state.formControls.destinationAccount.touched &&
                          !this.state.formControls.destinationAccount.valid
                          ? 'd-block'
                          : '',
                      )}
                      type="invalid"
                    >
                      <div
                        className={`${this.state.stylesMain}__form__content__errorFeedback__form`}
                      >
                        {
                          this.state.formControls.destinationAccount
                            .errorMessage
                        }
                      </div>
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mx-auto my-0">
                <Col className="text-left pl-0 pt-5 pb-2">
                  <span
                    className={classNames(
                      'text-format-title-light',
                      'font-weight-bold',
                    )}
                    style={{ color: '#da1a5e' }}
                  >
                    Amount
                  </span>
                </Col>
              </Row>
              <Row
                className={classNames(
                  'justify-content-start',
                  'mx-auto',
                  'my-0',
                )}
              >
                <Col className={classNames('px-0', 'pr-8')}>
                  <Form.Group controlId="assetAmount">
                    <Container fluid className="px-0">
                      <Row className={classNames('mx-auto', 'my-0')}>
                        <Col className={classNames('px-0')}>
                          <Form.Control
                            name="amount"
                            type="text"
                            onChange={(e) => {
                              this._changeHandler(e.target)
                            }}
                            onBlur={(e) => {
                              this._blurHandler(e.target)
                            }}
                            placeholder={
                              this.state.formControls.amount.placeholder
                            }
                            value={this.state.formControls.amount.value}
                            isInvalid={
                              !this.state.formControls.amount.valid &&
                              this.state.formControls.amount.touched
                            }
                            className={classNames(
                              `${this.state.stylesMain}__form__content__input`,
                              `${this.state.stylesMain}__form__content__input__amount`,
                              'input-form',
                            )}
                          />
                          <Form.Control.Feedback
                            className="position-absolute"
                            type="invalid"
                          >
                            <div
                              className={classNames(
                                `${this.state.stylesMain}__form__content__errorFeedback__form`,
                                `${this.state.stylesMain}__form__content__errorFeedback__amount`,
                              )}
                            >
                              {this.state.formControls.amount.errorMessage}
                            </div>
                          </Form.Control.Feedback>
                        </Col>
                        <Col
                          className={classNames('px-0', 'flex-grow-0')}
                          // style={{
                          //   pointerEvents: 'none',
                          //   cursor: 'default',
                          // }}
                        >
                          {/* <Form.Label
                            className={classNames(
                              'text-format-label-light',
                              'text-align',
                              `${this.state.stylesMain}__form__content__label`,
                            )}
                          >
                            <span style={{ color: '#da1a5e' }}>Credit</span>
                          </Form.Label> */}
                          <Select
                            options={this._listWhitelistedAssetsOptions()}
                            selectedItem={this.state.formControls.asset.value}
                            onChange={({ name, value }) => {
                              this._changeHandler({
                                name,
                                value,
                              })
                            }}
                          ></Select>
                        </Col>
                      </Row>
                    </Container>
                  </Form.Group>
                </Col>
              </Row>
              {this.props.isToBSC === false ? (
                <div>
                  <Row className="mx-auto my-0">
                    <Col className="text-left pl-0 pt-5 pb-2">
                      <span
                        className={classNames(
                          'text-format-title-light',
                          'font-weight-bold',
                        )}
                        style={{ color: '#da1a5e' }}
                      >
                        Memo
                      </span>
                    </Col>
                  </Row>
                  <Row
                    className={classNames(
                      'justify-content-start',
                      'mx-auto',
                      'my-0',
                    )}
                  >
                    <Col className={classNames('px-0', 'pr-8')}>
                      <Form.Group controlId="assetMemo">
                        <Container fluid className="px-0">
                          <Row className={classNames('mx-auto', 'my-0')}>
                            <Col className={classNames('px-0')}>
                              <Form.Control
                                name="memo"
                                type="text"
                                maxLength="22"
                                placeholder={
                                  this.state.formControls.memo.placeholder
                                }
                                onChange={(e) => {
                                  this._changeHandler(e.target)
                                }}
                                onBlur={(e) => {
                                  this._blurHandler(e.target)
                                }}
                                value={this.state.formControls.memo.value}
                                isInvalid={
                                  !this.state.formControls.amount.valid &&
                                  this.state.formControls.amount.touched
                                }
                                className={classNames(
                                  `${this.state.stylesMain}__form__content__input`,
                                  `${this.state.stylesMain}__form__content__input__memo`,
                                  'input-form',
                                )}
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div></div>
              )}
              <Row className="mx-auto my-0 mt-5">
                <Col className={classNames('px-0', 'pr-8', 'mt-5')}>
                  {this.props.isLogin ? (
                    <Button
                      type="submit"
                      disabled={this._isTransferDisabled()}
                      className={classNames(
                        'w-100',
                        'input-form',
                        `${this.state.stylesMain}__form__content__btn`,
                      )}
                    >
                      TRANSFER
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      disabled
                      className={classNames(
                        'w-100',
                        'input-form',
                        `${this.state.stylesMain}__form__content__btn`,
                      )}
                    >
                      TRANSFER
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Form>
        {this.state.error && (
          <Container>
            <Row>
              <Col
                className={`${this.state.stylesMain}__form__content__errorFeedback__submit`}
              >
                <span> {this.state.error} </span>
              </Col>
            </Row>
          </Container>
        )}
        <Modal
          isOpen={this.state.showResult}
          overlayClassName={`${this.state.stylesMain}__resultModal__overlay`}
          className={`${this.state.stylesMain}__resultModal__item`}
        >
          {this.state.showResult && (
            <ResultComponent {...resultProps}></ResultComponent>
          )}
        </Modal>
        <Modal
          isOpen={this.state.isTransactionModal}
          overlayClassName={`${this.state.stylesMain}__resultModal__overlay`}
          className={`${this.state.stylesMain}__resultModal__item`}
        >
          {this.state.isTransactionModal && (
            <CrossModalComponent {...resultCrossProps}></CrossModalComponent>
          )}
        </Modal>
      </React.Fragment>
    )
  }
}

HermesContent.propTypes = {
  txHashes: PropTypes.shape({
    state: PropTypes.shape({
      stellar: PropTypes.string,
      bsc: PropTypes.string,
    }),
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
  transferMap: PropTypes.shape({
    state: PropTypes.shape({
      amount: PropTypes.string,
      bscHash: PropTypes.string,
      partnerHash: PropTypes.string,
      bscAddr: PropTypes.string,
      partnerAddr: PropTypes.string,
      direction: PropTypes.string,
      finishedTime: PropTypes.string,
      state: PropTypes.string,
    }),
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
  whitelistedAssets: PropTypes.shape({
    state: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
  accountBalance: PropTypes.shape({
    state: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
  trustlines: PropTypes.shape({
    state: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
  stellarToBsc: PropTypes.func.isRequired,
  bscToStellar: PropTypes.func.isRequired,
  getTransferInfo: PropTypes.func.isRequired,
  isToBSC: PropTypes.bool.isRequired,
  getWhitelistAssets: PropTypes.func.isRequired,
  getAccountBalance: PropTypes.func.isRequired,
  getTrustlines: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  stopLoading: PropTypes.func.isRequired,
  ismasked: PropTypes.string,
  userAccount: PropTypes.string,
  isLogin: PropTypes.bool,
}
