import actionTypes from 'Components/hermes/hermesActionTypes'
import { getHermesInstance } from '@/utils/singleton'
import store from '@/store'

export const userAccount = (userAccount) => ({
  type: actionTypes.USER_ACCOUNT.SUCCESS,
  payload: userAccount,
})

export const collectTxHashesSuccess = ({ stellarTxHash, evrynetTxHash }) => ({
  type: actionTypes.ASYNC_COLLECT_HASHES.SUCCESS,
  payload: { stellarTxHash, evrynetTxHash },
})

export const collectTxHashesPending = (pendingStatus) => ({
  type: actionTypes.ASYNC_COLLECT_HASHES.PENDING,
  payload: pendingStatus,
})

export const collectTxHashesError = (error) => ({
  type: actionTypes.ASYNC_COLLECT_HASHES.FAILURE,
  payload: error,
})

export const asyncTransferInfoPending = (pendingStatus) => ({
  type: actionTypes.ASYNC_GET_TRANSFERINFO.PENDING,
  payload: pendingStatus,
})

export const asyncTransferInfoError = (error) => ({
  type: actionTypes.ASYNC_GET_TRANSFERINFO.FAILURE,
  payload: error,
})

export const asyncTransferInfoSuccess = ({
  amount,
  bscHash,
  partnerHash,
  bscAddr,
  partnerAddr,
  direction,
  finishedTime,
  state,
}) => ({
  type: actionTypes.ASYNC_GET_TRANSFERINFO.SUCCESS,
  payload: {
    amount,
    bscHash,
    partnerHash,
    bscAddr,
    partnerAddr,
    direction,
    finishedTime,
    state,
  },
})

export const getUserAccount = (e) => {
  return async (dispatch) => {
    dispatch(userAccount(e))
  }
}

export const getTransferInfo = (tx) => {
  const hermes = getHermesInstance()
  return async (dispatch) => {
    dispatch(asyncTransferInfoPending(true))
    try {
      let data = await hermes.getTransferInfo(tx)
      // console.log(data)
      dispatch(
        asyncTransferInfoSuccess({
          amount: data.amount,
          bscHash: data.evrynetHash,
          partnerHash: data.partnerHash,
          bscAddr: data.bscAddr,
          partnerAddr: data.partnerAddr,
          direction: data.direction,
          finishedTime: data.finishedTime,
          state: data.state,
        }),
      )
    } catch (e) {
      dispatch(asyncTransferInfoError(e))
    }
  }
}

export const stellarToBsc = ({ from, fromPriv, to, amount, asset }) => {
  const hermes = getHermesInstance()
  return async (dispatch) => {
    dispatch(collectTxHashesPending(true))
    // console.log(hermes)
    try {
      const { stellarTxHash, evrynetTxHash } = await hermes.stellarToEvrynet({
        from,
        fromPriv,
        amount,
        asset,
        to,
      })
      //   console.log({ stellarTxHash, evrynetTxHash })
      dispatch(collectTxHashesSuccess({ stellarTxHash, evrynetTxHash }))
    } catch (e) {
      dispatch(collectTxHashesError(e))
    }
  }
}

export const bscToStellar = ({ from, fromPriv, to, amount, asset, memo }) => {
  const hermes = getHermesInstance()
  //   console.log(hermes)
  //   console.log(112, memo)
  return async (dispatch) => {
    dispatch(collectTxHashesPending(true))
    try {
      const {
        stellarTxHash,
        evrynetTxHash,
      } = await hermes.evrynetMetaMaskToStellar({
        from,
        fromPriv,
        amount,
        asset,
        to,
        memo,
      })
      //   console.log(stellarTxHash)
      //   console.log(evrynetTxHash)

      dispatch(collectTxHashesSuccess({ stellarTxHash, evrynetTxHash }))
    } catch (e) {
      dispatch(collectTxHashesError(e))
    }
  }
}

export const getWhitelistAssetsSuccess = (whitelistedAssets) => ({
  type: actionTypes.ASYNC_GET_WHITELISTED_ASSETS.SUCCESS,
  payload: whitelistedAssets,
})

export const getWhitelistAssetsPending = (pendingStatus) => ({
  type: actionTypes.ASYNC_GET_WHITELISTED_ASSETS.PENDING,
  payload: pendingStatus,
})

export const getWhitelistAssetsError = (error) => ({
  type: actionTypes.ASYNC_GET_WHITELISTED_ASSETS.FAILURE,
  payload: error,
})

export const getWhitelistAssets = () => {
  const hermes = getHermesInstance()
  return async (dispatch) => {
    dispatch(getWhitelistAssetsPending(true))
    try {
      const whitelistedAssets = await hermes.client.ether.getWhitelistAssets()
      dispatch(getWhitelistAssetsSuccess(whitelistedAssets.assets))
    } catch (e) {
      dispatch(getWhitelistAssetsError(e))
    }
  }
}

export const getAccountBalanceSuccess = (whitelistedAssets) => ({
  type: actionTypes.ASYNC_GET_ACCOUNT_BALANCE.SUCCESS,
  payload: whitelistedAssets,
})

export const getAccountBalancePending = (pendingStatus) => ({
  type: actionTypes.ASYNC_GET_ACCOUNT_BALANCE.PENDING,
  payload: pendingStatus,
})

export const getAccountBalanceError = (error) => ({
  type: actionTypes.ASYNC_GET_ACCOUNT_BALANCE.FAILURE,
  payload: error,
})

export const getAccountBalance = ({ asset = {}, privateKey = '' }) => {
  const hermes = getHermesInstance()
  const client = store.getState().hermes[
    actionTypes.ASYNC_TOGGLE_WARP_SWITCH.stateKey
  ]
    ? hermes.client.stellar
    : hermes.client.evry
  return async (dispatch) => {
    dispatch(getAccountBalancePending(true))
    try {
      const address = client.getPublickeyFromPrivateKey(privateKey)
      const accountBalance = await client.getBalance({ address, asset })
      dispatch(getAccountBalanceSuccess(accountBalance.balance))
    } catch (e) {
      dispatch(getAccountBalanceError(e))
    }
  }
}

export const getTrustlinesSuccess = (trustlines) => ({
  type: actionTypes.ASYNC_GET_TRUSTLINES.SUCCESS,
  payload: trustlines,
})

export const getTrustlinesPending = (pendingStatus) => ({
  type: actionTypes.ASYNC_GET_TRUSTLINES.PENDING,
  payload: pendingStatus,
})

export const getTrustlinesError = (error) => ({
  type: actionTypes.ASYNC_GET_TRUSTLINES.FAILURE,
  payload: error,
})

export const getTrustlines = ({ privateKey = '' }) => {
  const hermes = getHermesInstance()
  return async (dispatch) => {
    dispatch(getTrustlinesPending(true))
    try {
      const address = hermes.client.stellar.getPublickeyFromPrivateKey(
        privateKey,
      )
      const { assets } = await hermes.client.stellar.getTrustlines(address)
      dispatch(getTrustlinesSuccess(assets))
    } catch (e) {
      dispatch(getTrustlinesError(e))
    }
  }
}

export const toggleTransferSwitch = () => ({
  type: actionTypes.ASYNC_TOGGLE_WARP_SWITCH.SUCCESS,
  payload: !store.getState().hermes[
    actionTypes.ASYNC_TOGGLE_WARP_SWITCH.stateKey
  ],
})
