import React, { Component } from 'react'
import Card from 'react-bootstrap/Card'
import classNames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { ReactComponent as BSCIcon } from '@/assets/icons/bsc.svg'
import { ReactComponent as StellarIcon } from '@/assets/icons/stellar.svg'
import { ReactComponent as SwapIcon } from '@/assets/icons/swap.svg'

export default class HermesChainTitle extends Component {
  constructor(props) {
    super(props)
    const chain = {
      BSC: BSCIcon,
      STELLAR: StellarIcon,
    }
    this.stylesMain = classNames({
      [this.constructor.name]: true,
    })
    this.stylesRow = `${this.stylesMain}__row`
    this.stylesAssetElem = `${this.stylesRow}__asset`
    this.stylesAssetText = `${this.stylesAssetElem}__text`
    this.stylesSwapElem = `${this.stylesRow}__swap`
    this.stylesSwapBubble = `${this.stylesSwapElem}__bubble`
    this.state = {
      styles: {
        main: this.stylesMain,
        asset: this.stylesAssetElem,
        swap: this.stylesSwapElem,
        row: this.stylesRow,
        assetText: this.stylesAssetText,
        swapBubble: this.stylesSwapBubble,
      },
      chain,
      swap: {
        src: chain.STELLAR,
        dest: chain.BSC,
      },
    }
  }

  _updateSwapTitle(prevProps) {
    // console.log(43, prevProps.isToBSC)
    // console.log(44, this.props.isToBSC)

    if (prevProps.isToBSC === this.props.isToBSC) return
    if (this.props.isToBSC) {
      this.setState({
        swap: {
          src: this.state.chain.STELLAR,
          dest: this.state.chain.BSC,
        },
      })
      return
    }
    this.setState({
      swap: {
        src: this.state.chain.BSC,
        dest: this.state.chain.STELLAR,
      },
    })
  }

  componentDidUpdate(prevProps) {
    this._updateSwapTitle(prevProps)
  }

  render() {
    const SrcAsset = this.state.swap.src
    const DestAsset = this.state.swap.dest
    return (
      <Card.Header className={this.state.styles.main}>
        <Container fluid>
          <Row className={this.state.styles.row}>
            <Col
              className={classNames(
                this.state.styles.asset,
                'text-format-title-light',
                'px-0',
              )}
            >
              <div className={this.state.styles.assetText}>
                <span style={{ color: '#fff' }}>From</span>{' '}
                <SrcAsset></SrcAsset>
              </div>
            </Col>
            <Col
              className={classNames(
                this.state.styles.swap,
                'px-0',
                'flex-grow-0',
              )}
              onClick={() => {
                this.props.isLogin === true
                  ? null
                  : this.props.toggleTransferSwitch()
              }}
            >
              <div className="speech-bubble">
                <span className={this.state.styles.swapBubble}>
                  Swap account
                </span>
              </div>
              {this.props.isLogin !== true ? (
                <SwapIcon></SwapIcon>
              ) : (
                <span></span>
              )}
              {/* <SwapIcon></SwapIcon> */}
            </Col>
            <Col
              className={classNames(
                this.state.styles.asset,
                'text-format-title-light',
                'px-0',
              )}
            >
              <div className={this.state.styles.assetText}>
                <span style={{ color: '#fff' }}>To</span>{' '}
                <DestAsset></DestAsset>
              </div>
            </Col>
          </Row>
        </Container>
      </Card.Header>
    )
  }
}

HermesChainTitle.propTypes = {
  isToBSC: PropTypes.bool.isRequired,
  toggleTransferSwitch: PropTypes.func.isRequired,
  isLogin: PropTypes.bool,
}
