import React, { Component } from 'react'
import { Navbar, Button } from 'react-bootstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as Logo } from '@/assets/icons/logo.svg'

import { Web3Provider } from '@ethersproject/providers'
import freighterApi from '@stellar/freighter-api'

import store from '../../store'

export default class Header extends Component {
  constructor(props) {
    super(props)
    // console.log(this)
    // const selectedAddress =
    //   window['ethereum'].selectedAddress == null
    //     ? ''
    //     : window['ethereum'].selectedAddress
    // console.log(selectedAddress)
    this.state = {
      styles: {
        main: classNames({
          [this.constructor.name]: true,
        }),
      },
      //   address: selectedAddress,
      address: '',
    }

    // this.connect = this.connectMetaMask.bind(this)
    this.connectMetaMask = this.connectMetaMask.bind(this)
    this.connectFreighter = this.connectFreighter.bind(this)
    this.logOut = this.logOut.bind(this)
  }

  async connectMetaMask() {
    let provider
    if (window['ethereum']) {
      provider = window['ethereum']
      try {
        await window['ethereum'].request({ method: 'eth_requestAccounts' })
      } catch (e) {
        // console.error(e)
      }
    } else if (window['web3']) {
      provider = window['web3'].currentProvider
    }

    // console.log(provider)
    const web3Provider = new Web3Provider(provider)
    const accounts = await web3Provider.listAccounts()
    const account = accounts[0]
    // console.log(account)

    this.setState({
      address: account,
    })
    this.props.getUserAccount(this.state.address)
    // window.location.reload()
  }

  connectFreighter() {
    const retrievePublicKey = async () => {
      let publicKey = ''
      let error = ''

      try {
        publicKey = await freighterApi.getPublicKey()
      } catch (e) {
        error = e
      }

      if (error) {
        return error
      }

      return publicKey
    }

    const result = retrievePublicKey()
    result.then((res) => {
      //   console.log(this.props)

      this.setState({
        address: res,
      })
      this.props.getUserAccount(this.state.address)
    })

    // window.location.reload()
  }

  componentDidUpdate() {
    if (store.getState().hermes.isToEvryData) {
      this.connectFreighter = this.connectFreighter.bind(this)
    } else {
      this.connectMetaMask = this.connectMetaMask.bind(this)
    }
    // this.connect = store.getState().hermes.isToEvryData
    //   ? this.connectFreighter.bind(this)
    //   : this.connectMetaMask.bind(this)

    // console.log(this.connect)

    // console.log(59, freighterApi.isConnected())
  }
  logOut() {
    window.location.reload()
  }

  render() {
    return (
      <Navbar
        expand="lg"
        sticky="top"
        style={{ backgroundColor: '#da1a5e', padding: '0 2rem' }}
        className={this.state.styles.main}
      >
        <Navbar.Brand>
          <Logo></Logo>
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text style={{ color: '#fff', fontSize: '14px' }}>
            {this.state.address ? (
              <div>
                <span style={{ margin: '0 10px' }}>{this.state.address}</span>
                <Button
                  style={{
                    color: '#da1a5e',
                    backgroundColor: '#fff',
                    borderRadius: '16px',
                    border: 'none',
                  }}
                  onClick={this.logOut}
                >
                  Logout
                </Button>
              </div>
            ) : (
              // <Button
              //   style={{ color: '#da1a5e', backgroundColor: '#fff' }}
              //   onClick={this.logOut}
              // >
              //   Logout
              // </Button>,

              <Button
                style={{
                  color: '#da1a5e',
                  backgroundColor: '#fff',
                  borderRadius: '16px',
                  border: 'none',
                }}
                onClick={
                  store.getState().hermes.isToEvryData
                    ? this.connectFreighter
                    : this.connectMetaMask
                }
              >
                Connect wallet
              </Button>
            )}
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

Header.propTypes = {
  userAccount: PropTypes.string,
  getUserAccount: PropTypes.func,
}
