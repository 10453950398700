import { connect } from 'react-redux'
import { compose } from 'redux'
import Hermes from 'Components/hermes/Hermes'
import {
  isToBscSelector,
  selectCollectedTxHashes,
  selectWhitelistedAssets,
  selectAccountBalance,
  selectTrustlines,
  selectTransferInfo,
  userAccount,
} from 'Components/hermes/hermesSelectors'
import {
  stellarToBsc,
  bscToStellar,
  getWhitelistAssets,
  toggleTransferSwitch,
  getAccountBalance,
  getTrustlines,
  getTransferInfo,
} from 'Components/hermes/hermesActions'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router'
import { withLoading } from 'Components/hoc'

const mapStateToProps = (state) => ({
  isToBSC: isToBscSelector(state),
  txHashes: selectCollectedTxHashes(state),
  whitelistedAssets: selectWhitelistedAssets(state),
  accountBalance: selectAccountBalance(state),
  trustlines: selectTrustlines(state),
  transferMap: selectTransferInfo(state),
  userAccount: userAccount(state),
})

const mapDispatchToProps = (dispatch) => {
  return {
    stellarToBsc: (payload) => dispatch(stellarToBsc(payload)),
    bscToStellar: (payload) => dispatch(bscToStellar(payload)),
    getTransferInfo: (payload) => dispatch(getTransferInfo(payload)),
    getWhitelistAssets: () => dispatch(getWhitelistAssets()),
    toggleTransferSwitch: () => dispatch(toggleTransferSwitch()),
    getAccountBalance: (payload) => dispatch(getAccountBalance(payload)),
    getTrustlines: (payload) => dispatch(getTrustlines(payload)),
    push: (location) => dispatch(push(location)),
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withRouter, withConnect, withLoading)(Hermes)
