import React, { Component } from 'react'
import classNames from 'classnames'
import HermesChainTitle from 'Components/hermes/hermes-chain-title/HermesChainTitle'
import HermesContent from 'Components/hermes/hermes-content/HermesContent'
import {
  Container,
  Row,
  Col,
  Card,
  //   InputGroup,
  //   Button,
  //   FormControl,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Header } from 'Components/header'
import { Footer } from 'Components/footer'

export default class Hermes extends Component {
  constructor(props) {
    super(props)

    this.stylesMain = classNames({
      [this.constructor.name]: true,
    })
    this.stylesContent = `${this.stylesMain}__content`
    this.state = {
      styles: {
        main: this.stylesMain,
        content: this.stylesContent,
      },
      inquire: true,
      txValue: '',
      userAccount: '',
      isLogin: false,
    }
  }

  async _changeHandler(txValue) {
    this.setState({
      txValue,
      inquire: false,
    })
  }

  onGetUserAccount(e) {
    this.setState({
      userAccount: e,
      isLogin: true,
    })
    // console.log(this.state.userAccount)
  }

  render() {
    return (
      <React.Fragment>
        <Header
          {...this.props}
          getUserAccount={(e) => {
            this.onGetUserAccount(e)
          }}
        ></Header>
        <Container fluid className={this.state.styles.main}>
          <Row>
            <Col>
              <Card className={this.state.styles.content}>
                <HermesChainTitle
                  {...this.props}
                  isLogin={this.state.isLogin}
                ></HermesChainTitle>
                <HermesContent
                  {...this.props}
                  userAccount={this.state.userAccount}
                  isLogin={this.state.isLogin}
                ></HermesContent>
              </Card>
              {/* <InputGroup className={`${this.stylesContent} mt-3`}>
                <FormControl
                  className={classNames('flex-grow-1', [
                    `${this.stylesMain}__form__content__input`,
                    'input-form',
                    'form-control',
                  ])}
                  value={this.state.txValue}
                  onChange={(e) => {
                    this._changeHandler(e.target.value)
                  }}
                  placeholder="Inquire stellar transaction hash"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Append>
                  <Button
                    disabled={this.state.inquire}
                    className={classNames([
                      this.state.inquire
                        ? `${this.stylesMain}__form__content__btn`
                        : 'btn btn-primary',
                    ])}
                    onClick={() =>
                      this.props.getTransferInfo(this.state.txValue)
                    }
                  >
                    Inquire
                  </Button>
                </InputGroup.Append>
              </InputGroup> */}
              <Container>
                <Row>
                  <Col className={`${this.stylesMain}__content__error`}>
                    <span>
                      {this.props.transferMap.state &&
                      this.props.transferMap.state.state == 2
                        ? `stellarTx: ${this.props.transferMap.state.partnerHash} `
                        : ''}
                    </span>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <div
          style={{
            position: 'relative',
          }}
        >
          <Footer></Footer>
        </div>
      </React.Fragment>
    )
  }
}

Hermes.propTypes = {
  txHashes: PropTypes.shape({
    state: PropTypes.shape({
      stellar: PropTypes.string,
      bsc: PropTypes.string,
    }),
    loading: PropTypes.bool,
    error: PropTypes.object,
  }).isRequired,
  transferMap: PropTypes.shape({
    state: PropTypes.shape({
      amount: PropTypes.string,
      bscHash: PropTypes.string,
      partnerHash: PropTypes.string,
      bscAddr: PropTypes.string,
      partnerAddr: PropTypes.string,
      direction: PropTypes.string,
      finishedTime: PropTypes.string,
      state: PropTypes.string,
    }),
    loading: PropTypes.bool,
    error: PropTypes.object,
  }).isRequired,
  whitelistedAssets: PropTypes.shape({
    state: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }).isRequired,
  accountBalance: PropTypes.shape({
    state: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
  trustlines: PropTypes.shape({
    state: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
  isToBSC: PropTypes.bool.isRequired,
  stellarToBsc: PropTypes.func.isRequired,
  bscToStellar: PropTypes.func.isRequired,
  getWhitelistAssets: PropTypes.func.isRequired,
  toggleTransferSwitch: PropTypes.func.isRequired,
  getAccountBalance: PropTypes.func.isRequired,
  getTrustlines: PropTypes.func.isRequired,
  getTransferInfo: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  stopLoading: PropTypes.func.isRequired,
  userAccount: PropTypes.string,
  isLogin: PropTypes.bool,
}
