import { createAsyncMutation } from '@/utils/store'

export const ASYNC_COLLECT_HASHES = createAsyncMutation('COLLECT_HASHES')
export const ASYNC_GET_TRANSFERINFO = createAsyncMutation('GET_TRANSFERINFO')

export const ASYNC_GET_WHITELISTED_ASSETS = createAsyncMutation(
  'GET_WHITELISTED_ASSETS',
)
export const ASYNC_TOGGLE_WARP_SWITCH = createAsyncMutation('IS_TO_EVRY')
export const ASYNC_GET_ACCOUNT_BALANCE = createAsyncMutation(
  'GET_ACCOUNT_BALANCE',
)
export const ASYNC_GET_TRUSTLINES = createAsyncMutation('GET_TRUSTLINES')

export const USER_ACCOUNT = createAsyncMutation('USER_ACCOUNT')

export default {
  ASYNC_COLLECT_HASHES,
  ASYNC_GET_WHITELISTED_ASSETS,
  ASYNC_TOGGLE_WARP_SWITCH,
  ASYNC_GET_ACCOUNT_BALANCE,
  ASYNC_GET_TRUSTLINES,
  ASYNC_GET_TRANSFERINFO,
  USER_ACCOUNT,
}
