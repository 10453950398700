import React, { useState, forwardRef } from 'react'
import { Form, Button, Container, Row, Col } from 'react-bootstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const MaskPasswordInput = forwardRef(function MaskPasswordInput(
  props,
  forwardedRef,
) {

  const { maskiconprops, ismasked } = props
  const [masked, setIsMasked] = useState(ismasked)
  const stylesMain = MaskPasswordInput.name

  const toggleIsMasked = () => {
    if(masked == 'password'){
      setIsMasked('text')
    }else{
      setIsMasked('password')
    }
  }



  return (
    <Container className="p-0">
      <Row className="m-0 p-0">
        <Col className={classNames('p-0', `${stylesMain}__input`)}>
          <Form.Control
            ref={forwardedRef}
            {...props}
            type={masked == 'password' ? 'password' : 'text'}
          ></Form.Control>
        </Col>
        <Col className={classNames('p-0', `${stylesMain}__mask`)}>
          <Button
            {...maskiconprops}
            variant=""
            className={classNames(`${stylesMain}__mask__btn`)}
            onClick={() => {
              toggleIsMasked()
            }}
          >
            <i
              className={classNames(
                'fas',
                `fa-eye${masked == 'password' ? '-slash' : ''}`,
                classNames(`text`),
              )}
            ></i>
          </Button>
        </Col>
      </Row>
    </Container>
  )
})

MaskPasswordInput.displayName = 'MaskPasswordInput'

MaskPasswordInput.defaultProps = {
  maskiconprops: {},
}

MaskPasswordInput.propTypes = {
  maskiconprops: PropTypes.object,
  ismasked: PropTypes.string,
}

export default MaskPasswordInput
