import React, { useState } from 'react'
// import { Card, Container, Row, Col, Button, Form } from 'react-bootstrap'
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Web3 from 'web3'
// import StellarBase from 'stellar-base'
import { useGetPageTypeItems } from 'Components/crossModal/crossModalHook'
import classNames from 'classnames'
// import { MaskPasswordInput } from 'Components/shared'
import { toCurrency } from '@/utils/format'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const CrossModal = (props) => {
  const [stylesMain] = useState('Cross')
  //   const [mark, setmark] = useState(true)
  //   const [mark] = useState(true)
  // const [valid, setvalid] = useState(false)
  // const [errorMessage, setErrorMessage] = useState('')
  // const [value, setValue] = useState('')
  const [value] = useState('')

  const {
    amount,
    memo,
    asset: { tokenName },
    src,
    dest,
    removeCrossResult,
    crossSubmit,
    crossBscSubmit,
    isToBSC,
  } = props

  const { items } = useGetPageTypeItems(props)

  //   const validateStellarSecretFormat = (value) => {

  //     if (!StellarBase.StrKey.isValidEd25519SecretSeed(value)) {
  //       setErrorMessage('Invalid Stellar secret key format.')
  //       setvalid(true)
  //       setmark(true)
  //     } else {
  //       setvalid(false)
  //       setmark(false)
  //     }

  //   }

  //   const validateBscSecretFormat = (value) => {
  //     if (!/^[a-f0-9]{64}$/i.test(value)) {
  //       setErrorMessage('Invalid BSC secret key format.')
  //       setvalid(true)
  //       setmark(true)
  //     } else {
  //       setvalid(false)
  //       setmark(false)
  //     }
  //   }

  const validateBscAddressFormat = (value) => {
    // console.log(value)
    let address = Web3.utils.isAddress(value)
    // console.log(address)
    return address
  }

  //   const privateHandleChange = (e) => {
  //    setValue(e.value)

  //    if(validateBscAddressFormat(src)){
  //       validateBscSecretFormat(e.value)
  //    }else{
  //       validateStellarSecretFormat(e.value)
  //    }

  //   }

  return (
    <Container className={classNames(stylesMain, 'p-0')}>
      <Row>
        <Col className={`${stylesMain}__content`}>
          <Card className={`${stylesMain}__content__card`}>
            <Card.Body className="p-0">
              <div
                className={classNames({
                  [`${stylesMain}__content__body`]: true,
                })}
              >
                <Container className="p-0">
                  <Row
                    className={classNames(
                      'm-0',
                      `${stylesMain}__content__body__row`,
                    )}
                  >
                    <Col
                      className={classNames(
                        `${stylesMain}__content__body__status`,
                      )}
                    >
                      <span>{items.title}</span>
                    </Col>
                  </Row>
                  <Row
                    className={classNames(
                      'm-0',
                      `${stylesMain}__content__body__row`,
                      'justify-content-start',
                    )}
                  >
                    <Col
                      sm={2}
                      className={classNames({
                        [`${stylesMain}__content__body__title`]: true,
                      })}
                    >
                      <span
                        className={classNames(
                          'label--dark',
                          'text-format-title',
                          `${stylesMain}__content__body__title__text`,
                        )}
                      >
                        From
                      </span>
                    </Col>
                    <Col
                      sm={9}
                      className={classNames({
                        [`${stylesMain}__content__body__title`]: true,
                      })}
                    >
                      <span
                        className={classNames(
                          'label--dark',
                          'text-format-title',
                        )}
                      >
                        {src}
                      </span>
                    </Col>
                    <Col sm={1} className="text-right">
                      <CopyToClipboard text={src}>
                        <Button
                          style={{ backgroundColor: '#da1a5e', border: 'none' }}
                        >
                          <img
                            src={require('@/assets/icons/copy.png')}
                            style={{ width: '2rem' }}
                            alt=""
                          />
                        </Button>
                      </CopyToClipboard>
                    </Col>
                  </Row>
                  <Row
                    className={classNames(
                      'm-0',
                      `${stylesMain}__content__body__row`,
                      'justify-content-start',
                    )}
                  >
                    <Col
                      sm={2}
                      className={classNames(
                        `${stylesMain}__content__body__title`,
                      )}
                    >
                      <span
                        className={classNames(
                          'label--dark',
                          'text-format-title',
                          `${stylesMain}__content__body__title__text`,
                        )}
                      >
                        To
                      </span>
                    </Col>
                    <Col
                      sm={9}
                      className={classNames({
                        [`${stylesMain}__content__body__title`]: true,
                      })}
                    >
                      <span
                        className={classNames(
                          'label--dark',
                          'text-format-title',
                        )}
                      >
                        {dest}
                      </span>
                    </Col>
                    <Col sm={1} className="text-right">
                      <CopyToClipboard text={dest}>
                        <Button
                          style={{ backgroundColor: '#da1a5e', border: 'none' }}
                        >
                          <img
                            src={require('@/assets/icons/copy.png')}
                            style={{ width: '2rem' }}
                            alt=""
                          />
                        </Button>
                      </CopyToClipboard>
                    </Col>
                  </Row>
                  <Row
                    className={classNames(
                      'm-0',
                      `${stylesMain}__content__body__row`,
                      'justify-content-start',
                    )}
                  >
                    <Col
                      sm={2}
                      className={classNames(
                        `${stylesMain}__content__body__title`,
                      )}
                    >
                      <span
                        className={classNames(
                          'label--dark',
                          'text-format-title',
                          `${stylesMain}__content__body__title__text`,
                        )}
                      >
                        Amount
                      </span>
                    </Col>
                    <Col
                      sm={9}
                      className={classNames({
                        [`${stylesMain}__content__body__title`]: true,
                      })}
                    >
                      <span
                        className={classNames(
                          'label--dark',
                          'text-format-title',
                        )}
                      >
                        <span
                          className={classNames(
                            'label--dark',
                            'text-format-title',
                          )}
                        >
                          {`${toCurrency(amount)} ${tokenName}`}
                        </span>
                      </span>
                    </Col>
                    <Col sm={1} className="text-right">
                      <CopyToClipboard text={amount}>
                        <Button
                          style={{ backgroundColor: '#da1a5e', border: 'none' }}
                        >
                          <img
                            src={require('@/assets/icons/copy.png')}
                            style={{ width: '2rem' }}
                            alt=""
                          />
                        </Button>
                      </CopyToClipboard>
                    </Col>
                  </Row>
                  {isToBSC === false ? (
                    <Row
                      className={classNames(
                        'm-0',
                        `${stylesMain}__content__body__row`,
                        'justify-content-start',
                      )}
                    >
                      <Col
                        sm={2}
                        className={classNames(
                          `${stylesMain}__content__body__title`,
                        )}
                      >
                        <span
                          className={classNames(
                            'label--dark',
                            'text-format-title',
                            `${stylesMain}__content__body__title__text`,
                          )}
                        >
                          Memo
                        </span>
                      </Col>
                      <Col
                        sm={9}
                        className={classNames({
                          [`${stylesMain}__content__body__title`]: true,
                        })}
                      >
                        <span
                          className={classNames(
                            'label--dark',
                            'text-format-title',
                          )}
                        >
                          <span
                            className={classNames(
                              'label--dark',
                              'text-format-title',
                            )}
                          >
                            {memo}
                          </span>
                        </span>
                      </Col>
                      <Col sm={1} className="text-right">
                        <CopyToClipboard text={memo}>
                          <Button
                            style={{
                              backgroundColor: '#da1a5e',
                              border: 'none',
                            }}
                          >
                            <img
                              src={require('@/assets/icons/copy.png')}
                              style={{ width: '2rem' }}
                              alt=""
                            />
                          </Button>
                        </CopyToClipboard>
                      </Col>
                    </Row>
                  ) : (
                    <div></div>
                  )}
                  {/* <hr className={classNames(`${stylesMain}__content__hr`)} />
                  {!validateBscAddressFormat(src) ? (
                    <Row
                      className={classNames(
                        'm-0',
                        `${stylesMain}__content__body__row`,
                      )}
                    >
                      <Col
                        className={classNames({
                          [`${stylesMain}__content__hash`]: true,
                        })}
                      >
                        <span
                          className={classNames(
                            `${stylesMain}__content__label`,
                            'd-block',
                          )}
                        >
                          Pvivate key of the send address
                        </span>
                        <Row className={classNames(`mt-3`)}>
                          <Col>
                            <MaskPasswordInput
                              ismasked="password"
                              className={classNames(
                                `${stylesMain}__content__input`,
                                'input-form',
                              )}
                              onChange={(e) => {
                                privateHandleChange(e.target)
                              }}
                              value={value}
                              name="sourceAccount"
                              type="text"
                            />
                            <Form.Control.Feedback
                              className={classNames(
                                'position-absolute',
                                valid ? 'd-block' : '',
                              )}
                              type="invalid"
                            >
                              <div
                                className={`${stylesMain}__form__content__errorFeedback__form`}
                              >
                                {errorMessage}
                              </div>
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                        <span
                          className={classNames(
                            'text-format-label',
                            'd-block',
                            'mt-6',
                          )}
                        >
                          BSC will not store your private key, the private key
                          is only used to initiate the transfer.
                        </span>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )} */}
                  <Row
                    className={classNames(
                      'm-0',
                      `${stylesMain}__content__body__row`,
                    )}
                  >
                    <Col className={`${stylesMain}__close`}>
                      <button
                        type="button"
                        onClick={() => {
                          removeCrossResult()
                        }}
                        className={classNames(
                          `btn btn-light ${stylesMain}__close__btn mr-4`,
                        )}
                      >
                        Cancel
                      </button>
                      {!validateBscAddressFormat(src) ? (
                        <button
                          type="button"
                          onClick={() => {
                            crossSubmit(value)
                          }}
                          //   disabled={mark}
                          className={classNames(
                            `btn btn-info ${stylesMain}__close__btn `,
                          )}
                          style={{ backgroundColor: '#da1a5e', border: 'none' }}
                        >
                          Confirm
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            crossBscSubmit()
                          }}
                          className={classNames(
                            `btn btn-info ${stylesMain}__close__btn `,
                          )}
                          style={{ backgroundColor: '#da1a5e', border: 'none' }}
                        >
                          Confirm
                        </button>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

CrossModal.propTypes = {
  push: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired,
  memo: PropTypes.string.isRequired,

  asset: PropTypes.shape({
    tokenName: PropTypes.string,
  }).isRequired,
  src: PropTypes.string.isRequired,
  dest: PropTypes.string.isRequired,
  removeCrossResult: PropTypes.func.isRequired,
  crossSubmit: PropTypes.func.isRequired,
  crossBscSubmit: PropTypes.func.isRequired,
  ismasked: PropTypes.string,
  isToBSC: PropTypes.bool.isRequired,
}

export default CrossModal
