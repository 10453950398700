import Hermes, { HermesConfig, initHermesConfig } from 'hermes-wrap-test'
import Common from 'ethereumjs-common'
let hermesInstance
export default function getHermesInstance() {
  if (!hermesInstance) {
    let config = new HermesConfig()
    
    //   MAINNET
    config.ether.contract.etherCustodian.address =
      '0xC51B4f086afD7b1Fb6B9137DF65126e70212Ea60'
    config.ether.contract.etherErc20ContractAddr.address =
      '0xf486ad071f3bee968384d2e39e2d8af0fcf6fd46'
    config.ether.customChain = Common.forCustomChain(
      'mainnet',
      {
        name: 'BSC',
        chainId: 56,
        networkId: 56,
      },
      'constantinople',
    )
    config.evrynet.atomicStellarDecimalUnit = 7
    config.evrynet.atomicEvryDecimalUnit = 18
    //
    config.grpc.host = 'https://hermes.velo.org/hermeswarp'
    //
    config.evrynet.provider = 'https://bsc-dataseed.binance.org'
    config.stellar.custodianAccount =
      'GCPZJGRVNNTULDHYMGGHN2ITTK6CBOADIUH45SVYYKK4UR36MWQXIB4E'
    config.stellar.issuer =
      'GDM4RQUQQUVSKQA7S6EM7XBZP3FCGH4Q7CL6TABQ7B2BEJ5ERARM2M5M'
    config.stellar.network = 'PUBLIC'

    /**  ---------------------------------------------------------------------------*/
    initHermesConfig(config)
    hermesInstance = new Hermes()
  }
  return hermesInstance
}
