import { useState } from 'react'
import { ReactComponent as BSCIcon } from '@/assets/icons/bsc.svg'
import { ReactComponent as StellarIcon } from '@/assets/icons/stellar-dark.svg'

export const useGetPageTypeItems = (props) => {
  function _getChain(isToBSC) {
    return isToBSC
      ? {
          src: StellarIcon,
          dest: BSCIcon,
        }
      : {
          src: BSCIcon,
          dest: StellarIcon,
        }
  }

  function _getPageItems(props) {
    return {
      chain: _getChain(props.isToBSC),
      title: 'Cross-Chain Transaction',
    }
  }

  const [items] = useState(_getPageItems(props))
  return { items }
}
